import { useState, useEffect, useMemo } from "react";
import "./App.css";
import { Routes, Route, Link, Navigate, useNavigate, useLocation } from "react-router-dom";

import { Login, Dashboard, PersonalInfo, Submission, NotFound } from "./view";

import { Provider } from 'react-redux'
import { createStore } from 'redux'
// import rootReducer from './reducers'


import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore"



import Typography from '@mui/material/Typography';

import { MenuBar } from "./components/menu-bar"

import { spacing } from "./styles/spacing"

import { PATH } from "./constants/path"

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { CircularProgress } from "@mui/material";



// Configure Firebase.
const config = {
  // apiKey: "AIzaSyA1Zg0gY0sQHnsAnVY6_RjncaOWtc-ol4w",
  // authDomain: "kkgrandmock2022.firebaseapp.com",
  // projectId: "kkgrandmock2022",
  // storageBucket: "kkgrandmock2022.appspot.com",
  // messagingSenderId: "629894085696",
  // appId: "1:629894085696:web:614ab7b1715514db3fb3a5",
  // measurementId: "G-91Q0TXGL6P",
  apiKey: "AIzaSyA1Zg0gY0sQHnsAnVY6_RjncaOWtc-ol4w",
  authDomain: "kkgrandmock2022.firebaseapp.com",
  projectId: "kkgrandmock2022",
  storageBucket: "kkgrandmock2022.appspot.com",
  messagingSenderId: "629894085696",
  appId: "1:629894085696:web:4d41befb217a5faa3fb3a5",
  measurementId: "G-7JJCMXCHR5"
};

// const FirebaseContext = React.createContext(null)
const app = firebase.initializeApp(config);

// const store = createStore(rootReducer)


function App(props) {


  // Material Dashboard 
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );


  const [signedIn, setSignedIn] = useState(false)
  const [authLoaded, setAuthLoaded] = useState(false)


  // Firebase Initialize
  const auth = getAuth();
  const user = auth.currentUser;

  const db = getFirestore();


  const navigate = useNavigate()


  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // console.log("user signined in : ", uid)
      // ...
      setSignedIn(true)
    } else {
      // User is signed out
      // ...
      console.log("User signed out")
      setSignedIn(false)
      // navigate("/")
    }

    if (!authLoaded) {
      setAuthLoaded(true)
    }
  });

  const handleSignOut = () => {
    signOut(auth).then(() => {
      console.log("Sign Out Success!")
      navigate('/login')
    }).catch((error) => {
      console.error("Sign Out Error: ", error)
    })
  }

  function AuthWrapper({ children, ...props }) {
    console.log("Children: ", children)
    return <Route
      {...props}
      element={({ location }) => user ? (
        children
      ) : (<Navigate to={{
        pathname: "/login",
        state: {
          from: location
        }
      }} />)} />
  }

  if (authLoaded) {
    return (

      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Grand Mock 2022"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              signOutHandler={handleSignOut}
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Routes>

          {signedIn ?
            <>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </> :
            <>
              <Route path={PATH.LOGIN} element={<Login />} />

              <Route path="*" element={<Navigate to={PATH.LOGIN} />} />
            </>
          }


          {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </ThemeProvider>
    )
  } else {
    return (<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
      <CircularProgress />
    </div >)
  };
}

export default App;
