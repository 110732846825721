import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

// // replace console.* for disable log on production
// // if (process.env.NODE_ENV === 'production') {
// console.log = () => { }
// // console.error = () => {}
// console.debug = () => { }
// // }

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>

  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
