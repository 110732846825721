
import { useState, useEffect } from "react"

import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  TextField,
  CircularProgress,
  Checkbox,
  Tooltip,
  Snackbar, Alert,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';




import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


import ProgressOverview from "components/dashboard/ProgressOverview";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import { RootContainer } from '../components';

import { spacing } from "../styles"

import { PATH, SUBJECTS, SUBMISSION_STATUS } from '../constants'

import { deleteField, doc, getDoc, getFirestore, onSnapshot, runTransaction, setDoc, Timestamp } from "firebase/firestore"
import { collection, query, where, getDocs } from "firebase/firestore";

import moment from "moment";
import { parseSubmissionStatus } from "utils";

import { Document, Page, pdfjs } from 'react-pdf'

import { getAuth } from "firebase/auth";

import Slider from '@mui/material/Slider';
import { indexof } from "stylis";
import { getStorage, ref, getDownloadURL, getBytes } from "firebase/storage";
import { concatTransformationMatrix } from "pdf-lib";

const Input = styled(MuiInput)`
  width: 42px;
`;

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';



const scoreMatrix = [3, 3, 3, 4, 3, 3, 5, 4, 3, 4, 7, 7, 10, 11, 4, 8, 10, 13]
const fullScore = scoreMatrix.reduce((accu, curr) => {
  return accu + curr
}, 0)



const paperColumns = [
  { field: 'progress', headerName: 'Progress', width: 125 },
  { field: 'score', headerName: 'Score', width: 100 },
  { field: 'marking', headerName: 'Marking', width: 200 },
  { field: 'missing', headerName: 'Missing', width: 550 },
  { field: 'seatNo', headerName: 'Seat No', width: 100 },
  { field: 'uid', headerName: 'ID', width: 300 },


]

export function Dashboard() {

  const db = getFirestore()
  const auth = getAuth();
  const storage = getStorage()
  const user = auth.currentUser;
  let uid = null;

  if (user !== null) {
    // The user object has basic properties such as display name, email, etc.
    uid = user.uid
  }

  const userDocRef = doc(db, 'markers', uid)
  const candidateDocRef = (cid) => doc(db, 'users', cid)

  const submissionColRef = collection(db, 'submissionCore')
  const submissionDocRef = (uid) => doc(db, 'submissionCore', `${uid}_Core`)


  const [readingDB, setReadingDB] = useState(true)


  const [displayName, setDisplayname] = useState(null)
  // const [userDoc, setUserDoc] = useState(null)

  const [waitingApproval, setWaitingApproval] = useState(true)
  const [approved, setApproved] = useState(false)


  const [isMarking, setIsMarking] = useState(false)


  const [progress, setProgress] = useState(0)

  const [candidateId, setCandidateId] = useState(null)
  const [candidtateProfile, setCandidtateProfile] = useState(null)
  const [submissions, setSubmissions] = useState([])
  const [selectedSubmission, setSelectedSubmission] = useState(null)
  const [submission, setSubmission] = useState(null)
  const [paper, setPaper] = useState(null)
  const [paperPath, setPaperPath] = useState(null)
  const [downloadingPaper, setDownloadingPaper] = useState(false)

  const [markingRange, setMarkingRange] = useState(Array(scoreMatrix.length).fill(false))
  const [markings, setMarkings] = useState({})

  const [loadingPaper, setLoadingPaper] = useState(false)

  const [score, setScore] = useState(0)
  const [comment, setComment] = useState("")


  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false)
  const [successAlertMessage, setSuccessAlertMessage] = useState(null)
  const [errorAlertMessage, setErrorAlertMessage] = useState(null)


  // PDF Controls
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageHeight, setPageHeight] = useState(800)
  const [defaultPDFPage, setdefaultPDFPage] = useState(1)

  // Setting Dialog Controls
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)

  const handleOpenSettingsDialog = () => {
    setShowSettingsDialog(true)
  }
  const handleCloseSettingsDialog = () => {
    setShowSettingsDialog(false)
  }

  // Testing
  useEffect(() => {
    // setMarkingRange([0, 1])
  }, [])


  ///////////////////////////////////////////////
  //
  // Life Cycle
  //
  ///////////////////////////////////////////////


  // Marker Data Effect Hook
  useEffect(() => {

    const unsub = onSnapshot(userDocRef, (docSnap) => {
      const data = docSnap.data()

      console.log('Snapshot data received!')

      // if (readingDB) {
      // setUserDoc(data)

      if (data) {
        setApproved(data.approved ? true : false)
        setDisplayname(data.displayName)

        if (data.displayName) {
          setWaitingApproval(true)
        } else {
          setWaitingApproval(false)
        }

      } else {

      }


      setReadingDB(false)
      // } 
    })

    return () => unsub()

  }, [])


  // Submission Collection Effect Hook
  useEffect(() => {
    const unsub = onSnapshot(submissionColRef, (colSnap) => {
      console.log('Snapshot data received!', colSnap.docs)

      const newSubmissions = []

      colSnap.forEach((docSnap) => {
        const data = docSnap.data()

        // console.log(data)

        const newSubmission = { id: data.userID, uid: data.userID, seatNo: data.seatNo }

        const scores = data.scores

        let totalScore = 0
        let progress = 0
        const completed = []
        const missing = [...Array(scoreMatrix.length).keys()]
        const marking = []


        if (scores) {

          // If question is marking then wont show as complete not count towards total mark
          Object.entries(scores).forEach(([q_no, value]) => {
            q_no = parseInt(q_no)
            if (value.lock) {

              marking.push(q_no)

              missing.splice(missing.indexOf(q_no), 1)
            } else if (!isNaN(value.score)) {

              totalScore += value.score

              completed.push(q_no)

              missing.splice(missing.indexOf(q_no), 1)
            }




          })

          progress = completed.length


        }

        newSubmission['progress'] = `${progress}/${scoreMatrix.length}`
        newSubmission['completed'] = progress === scoreMatrix.length
        newSubmission['score'] = `${totalScore}/${fullScore}`
        newSubmission['missing'] = missing.map((value) => `Q${value + 1}`).join(", ")
        newSubmission['missingRaw'] = missing
        newSubmission['marking'] = marking.length > 0 ? marking.map((value) => `Q${value + 1}`).join(", ") : "NA"


        newSubmissions.push(newSubmission)

      })

      setProgress(newSubmissions.map((submission => submission.completed)).filter(Boolean).length)

      setSubmissions(newSubmissions)

      console.log(newSubmissions)

    })

    return () => unsub()

  }, [])

  // // get candidate profile on candidate id change
  // useEffect(() => {
  //   const candidateDocRef = doc(db, 'users', candidateId)

  //   console.log("Will get data for candidate: ", candidateId)
  //   getDoc(candidateDocRef).then((docSnap => {
  //     if (docSnap.exists()) {
  //       console.log("Candidate: ", docSnap.data())
  //       setCandidtateProfile(docSnap.data())
  //     }
  //   }))


  // }, [candidateId, db])

  // Download Paper
  useEffect(() => {
    if (paperPath) {
      console.log("Paper path: ", paperPath)

      getBytes(ref(storage, paperPath))
        .then((bytes) => {
          setPaper(bytes)
        })
        .catch((e) => {
          console.error(e)
        })
      // getDownloadURL(ref(storage, paperPath))
      //   .then((url) => {
      //     // `url` is the download URL for 'images/stars.jpg'

      //     // // This can be downloaded directly:
      //     // const xhr = new XMLHttpRequest();
      //     // xhr.responseType = 'blob';
      //     // xhr.onload = (event) => {
      //     //   const blob = xhr.response;
      //     // };
      //     // xhr.open('GET', url);
      //     // xhr.send();

      //     // // Or inserted into an <img> element
      //     // const img = document.getElementById('myimg');
      //     // img.setAttribute('src', url);

      //     setPaper(url)
      //   })
      //   .catch((error) => {
      //     // Handle any errors
      //   });
    }
  }, [paperPath, storage])

  ///////////////////////////////////////////////
  //
  // UI 
  //
  ///////////////////////////////////////////////


  const questionMarkingZone = (questionData, index) => {
    // const questionData = markings[index] ? markings[index] : { 'comment': "", 'score': 0 }


    return (<>
      <Typography >
        {`Question ${index + 1}`}
      </Typography>
      <TextField
        id="outlined-multiline-flexible"
        label="Comments"

        multiline
        value={questionData["comment"]}
        onChange={(event) => { handleCommentChange(index, event.target.value) }}
        style={{ margin: spacing.list }}
      />

      <Box sx={{ width: 250 }}>
        <Typography variant="body2">
          Score
        </Typography>
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: spacing.regular }}>
          <Grid item>
          </Grid>
          <Grid item xs>
            <Slider
              value={typeof questionData['score'] === 'number' ? questionData['score'] : 0}
              onChange={(event, value) => {
                handleScoreChange(index, value);
              }}
              min={0}
              max={scoreMatrix[index]}
            />
          </Grid>
          <Grid item>
            <Input
              value={questionData['score']}
              size="small"
              onChange={(event) => {
                handleScoreChange(index, event.target.value === '' ? '' : Number(event.target.value));

              }}
              // onBlur={handleBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
      </Box>

    </>)
  }




  ///////////////////////////////////////////////
  //
  // Action Handle
  //
  ///////////////////////////////////////////////


  const launchSuccessAlert = (message) => {
    setSuccessAlertMessage(message)
    setOpenSuccessAlert(true)
  }

  const launchErrorAlert = (message) => {
    setErrorAlertMessage(message)
    setOpenErrorAlert(true)
  }

  const handleSuccessAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessAlert(false);
  };

  const handleErrorAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorAlert(false);
  };



  const handleProfileInit = async () => {
    console.log(uid)
    try {
      await setDoc(userDocRef, { displayName, approved: false }, { merge: true })

    } catch (e) {

    }
  }


  const handleMarkingRangeChange = (index, event) => {
    const newMarkingRange = [...markingRange]
    newMarkingRange[index] = event.target.checked
    setMarkingRange(newMarkingRange)
    console.log("Marking range change for : ", index, event.target.checked, newMarkingRange)
  }

  const handleMarkingRangeSelectAll = () => {

    setMarkingRange(Array(scoreMatrix.length).fill(true))
  }

  const handleMarkingRangeSelectNone = () => {
    setMarkingRange(Array(scoreMatrix.length).fill(false))
  }

  const handleCellSelect = (cell) => {
    console.log("Selected cell: ", cell.id)
    setSelectedSubmission(cell.id)
  }

  const handleCommentChange = (index, comment) => {
    const newMarkings = { ...markings }
    const questionData = newMarkings[index] ? newMarkings[index] : {}
    questionData["comment"] = comment
    questionData["commentBy"] = uid
    questionData["commentAt"] = new Date()
    newMarkings[index] = questionData
    setMarkings(newMarkings)
  }

  const handleScoreChange = (index, score) => {

    const newMarkings = { ...markings }
    const questionData = newMarkings[index] ? newMarkings[index] : {}
    questionData["score"] = score
    questionData["scoreBy"] = uid
    questionData["scoreAt"] = new Date()


    newMarkings[index] = questionData

    setMarkings(newMarkings)
  }

  const getRandomSubmission = () => {
    // console.log(submissions)

    const filteredSubmissions = submissions.filter(submission => {
      for (const index in markingRange) {
        // console.log("index", index, typeof index)
        if (markingRange[index] && submission.missingRaw.includes(parseInt(index))) {
          return true
        }
      }
      return false

    })

    // console.log("Filtered submissions: ", filteredSubmissions)

    return filteredSubmissions[Math.floor(Math.random() * filteredSubmissions.length)].id
  }


  const handleLoadSubmission = async (id) => {

    const lockingRange = markingRange.reduce((accu, curr, index) => {
      if (curr) {
        return [...accu, index]
      } else {
        return accu
      }
    }, [])

    console.log("Locking range: ", lockingRange)

    try {
      await runTransaction(db, async (transaction) => {

        const markerDoc = await transaction.get(userDocRef)

        if (!markerDoc.exists()) {
          launchErrorAlert()
          throw "maker doc missing!"
        }

        const submissionDoc = await transaction.get(submissionDocRef(id))

        if (!submissionDoc.exists()) {
          launchErrorAlert()
          throw "Submission Doc Missing!"
        }

        const submissionUser = submissionDoc.data().userID

        const submissionUserDoc = await transaction.get(candidateDocRef(submissionUser))
        if (!submissionUserDoc.exists()) {
          console.error("Submission user missing!")
        }


        const makerData = markerDoc.data()
        const lockedSubmissionID = makerData.lock

        let lockedSubmissionData = null

        if (lockedSubmissionID) {
          // Clear lock if lock on other submission exists
          const lockedSubmissionDoc = await transaction.get(submissionDocRef(lockedSubmissionID))
          if (!lockedSubmissionDoc.exists()) {
            launchErrorAlert()
            throw "locked submission missing!"
          }
          lockedSubmissionData = lockedSubmissionDoc.data()
          const lockedSubmissionScores = lockedSubmissionData['scores']

          console.log("Locked submission : ", lockedSubmissionScores)

          Object.entries(lockedSubmissionScores).forEach(([key, value]) => {

            console.log("checking score: ", value, key, uid)
            if (value.lock === uid) {
              delete value.lock
            }
          })

          console.log("Cleared scores: ", lockedSubmissionScores)

          transaction.update(submissionDocRef(lockedSubmissionID), lockedSubmissionData)

          // delete makerData.lock

          makerData['lock'] = deleteField()

          transaction.update(userDocRef, makerData)

        }


        const submissionData = id === lockedSubmissionID ? lockedSubmissionData : submissionDoc.data() // Handle case where locked submission is same as update submission the lock will not update

        // Check if score prop is present under submission data
        // if (submissionData.score) {

        // } else {

        // }

        const newSubmissionData = { ...submissionData }
        const newScores = submissionData.scores ? { ...submissionData.scores } : {}

        lockingRange.forEach((questionToLock) => {

          const scoreToLock = newScores[questionToLock] ? newScores[questionToLock] : {}
          if (scoreToLock && scoreToLock['lock'] && scoreToLock['lock'] !== uid) {
            // Existing lock present
            launchErrorAlert(`Question is being locked by other marker`)
            throw `Question is being locked by user ${scoreToLock['lock']}`
          }

          scoreToLock['lock'] = uid
          newScores[questionToLock] = scoreToLock


        })

        newSubmissionData['scores'] = newScores
        console.log("New submission data: ", newSubmissionData)
        transaction.update(submissionDocRef(id), newSubmissionData)
        transaction.update(userDocRef, { lock: id })


        setMarkings(newScores)
        setSubmission(newSubmissionData)
        setCandidtateProfile(submissionUserDoc.data())
        setCandidateId(id)
        setPaperPath(submissionData.paper1)
      })



      setIsMarking(true)

    } catch (e) {

      console.error(e)

    } finally {

    }



  }

  const handleSubmitScore = async () => {
    // Make sure each marked question is not null
    console.log(markingRange)
    markingRange.forEach((value, q_no) => {
      console.log(q_no, markings[q_no])

      if (value && (!markings[q_no] || isNaN(markings[q_no].score))) {
        console.error(`Invalid score for Q${q_no + 1}`)
        launchErrorAlert(`Invalid score for Q${q_no + 1}`)
        return
      }
    })

    console.log("markings: ", markings)
    try {
      await setDoc(submissionDocRef(candidateId), { scores: markings }, { merge: true })
      console.log("CID", candidateId)
      launchSuccessAlert("Submit successful")

    } catch (e) {
      launchErrorAlert("Failed to submit. Please try again later")
      console.error(e)
    }
  }


  const handleUnlock = async () => {
    try {
      await runTransaction(db, async (transaction) => {

        const markerDoc = await transaction.get(userDocRef)

        if (!markerDoc.exists()) {
          launchErrorAlert()
          throw "maker doc missing!"
        }

        const submissionDoc = await transaction.get(submissionDocRef(candidateId))

        if (!submissionDoc.exists()) {
          launchErrorAlert()
          throw "Submission Doc Missing!"
        }

        const makerData = markerDoc.data()
        const lockedSubmissionID = makerData.lock

        let lockedSubmissionData = null

        if (lockedSubmissionID) {
          // Clear lock if lock on other submission exists
          const lockedSubmissionDoc = await transaction.get(submissionDocRef(lockedSubmissionID))
          if (!lockedSubmissionDoc.exists()) {
            launchErrorAlert()
            throw "locked submission missing!"
          }
          lockedSubmissionData = lockedSubmissionDoc.data()
          const lockedSubmissionScores = lockedSubmissionData['scores']

          console.log("Locked submission : ", lockedSubmissionScores)

          Object.entries(lockedSubmissionScores).forEach(([key, value]) => {

            console.log("checking score: ", value, key, uid)
            if (value.lock === uid) {
              delete value.lock
            }
          })

          console.log("Cleared scores: ", lockedSubmissionScores)

          transaction.update(submissionDocRef(lockedSubmissionID), lockedSubmissionData)

          // delete makerData.lock

          // console.log("marker data: ", makerData)

          makerData['lock'] = deleteField()

          transaction.update(userDocRef, makerData)

        }


      })



      setIsMarking(false)
      setSubmission(null)
      setCandidateId(null)
      setCandidtateProfile(null)
      setMarkings(null)
      // setPaper(null)

    } catch (e) {

      console.error(e)

    } finally {

    }
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Document file="Document.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      // style={{ backgroundColor: "#0f0" }}
      >
        {/* <Container maxWidth="lg"
          style={{ backgroundColor: "#00f", width: "100%" }}

        > */}
        {/* <Typography
                    sx={{ mb: 3 }}
                    variant="h4"
                >
                    Account
                </Typography> */}

        {isMarking ? <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            maxWidth={300}
          >
            <Card >
              <CardHeader
                // subheader="Please complete the following session"
                title="Marking"
                action={<>
                  <IconButton color="primary" component="span" onClick={() => {
                    handleUnlock()
                  }}>
                    <Tooltip title={"Exit without saving"}>
                      <CloseIcon />

                    </Tooltip>
                  </IconButton>
                </>}
              />
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >

                  {readingDB ? <CircularProgress /> :
                    <>

                      {markingRange.map((value, index) => {


                        return value && questionMarkingZone(markings[index] ? markings[index] : { 'comment': "", 'score': 0 }, index)



                      })}



                    </>
                  }


                </Box>
              </CardContent>
              <Divider />



              {/* <Divider /> */}

              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={async () => {
                    await handleSubmitScore()

                    // const randomSubmissionID = getRandomSubmission()
                    // console.log("Will load random submission :", randomSubmissionID)
                    // handleLoadSubmission(randomSubmissionID)
                  }}
                >
                  Submit
                </Button>

                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  onClick={async () => {
                    await handleSubmitScore()

                    const randomSubmissionID = getRandomSubmission()
                    console.log("Will load random submission :", randomSubmissionID)
                    handleLoadSubmission(randomSubmissionID)
                  }}
                >
                  Submit and Next
                </Button>

              </CardActions>
            </Card>
          </Grid>


          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <form
              autoComplete="off"
              noValidate
            >
              <Card>
                <CardHeader
                  // subheader="Please complete the following session"
                  title={candidtateProfile ? `${candidtateProfile.studentID} ${candidtateProfile.enName} ${submission.seatNo}` : "-"}
                  action={<>

                    <IconButton color="primary" component="span" onClick={() => {
                      handleOpenSettingsDialog()
                    }}>
                      <SettingsIcon />
                    </IconButton>

                    <TextField
                      label={"Page Height"}
                      value={pageHeight}
                      onChange={(event) => {
                        setPageHeight(parseInt(event.target.value))
                      }}
                    />
                    <IconButton color="primary" component="span" onClick={() => {
                      setPageHeight(pageHeight + 100)
                      console.log("Current page height: ", pageHeight + 100)
                    }}>
                      <AddIcon />
                    </IconButton>
                    <IconButton color="primary" component="span" onClick={() => {
                      setPageHeight(pageHeight - 100)
                      console.log("Current page height: ", pageHeight - 100)

                    }}>
                      <RemoveIcon />
                    </IconButton>
                  </>}

                />
                {/* <Divider /> */}

                <CardContent >
                  <div style={{ overflowY: 'scroll', overflowX: 'scroll', height: '800px' }}>
                    <Document file={paper}
                      onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);

                        console.log("Will load pdf page: ", defaultPDFPage)
                        setPageNumber(defaultPDFPage)
                      }}

                      onLoadError={console.error}>
                      {/* {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} height={pageHeight} />
                      ))} */}

                      <Page pageNumber={pageNumber} height={pageHeight} />

                    </Document>

                    {submission.remark && <Typography>
                      {`Remark: ${submission.remark}`}
                    </Typography>}

                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Pagination count={numPages} color="primary" onChange={(event, value) => {
                      setPageNumber(value)
                    }}
                      size={"large"} />
                  </div>



                </CardContent>

              </Card>
            </form>
          </Grid>
        </Grid>
          :

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              maxWidth={300}
            >
              <Card >
                <CardHeader
                  // subheader="Please complete the following session"
                  title="Settings"
                  action={<>
                    <Button
                      onClick={handleMarkingRangeSelectAll}>
                      All
                    </Button>

                    <Button
                      onClick={handleMarkingRangeSelectNone}>
                      None
                    </Button>

                  </>}

                />
                <CardContent>
                  <FormGroup >

                    <Grid container>
                      <Grid item>
                        {scoreMatrix.slice(0, scoreMatrix.length / 2).map((value, index) => <FormControlLabel checked={markingRange[index] ? markingRange[index] : false} control={<Checkbox />} label={`Q${index + 1}`} onChange={(event) => { handleMarkingRangeChange(index, event) }} />)}

                      </Grid>
                      <Grid item>
                        {scoreMatrix.slice(scoreMatrix.length / 2, scoreMatrix.length).map((value, index) => {
                          index = scoreMatrix.length / 2 + index
                          return <FormControlLabel checked={markingRange[index] ? markingRange[index] : false} control={<Checkbox />} label={`Q${index + 1}`} onChange={(event) => { handleMarkingRangeChange(index, event) }} />
                        })}

                      </Grid>

                    </Grid>


                  </FormGroup>

                </CardContent>
                <Divider />




                <CardActions>

                  <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>


                    <Button
                      color="primary"
                      fullWidth
                      variant="text"
                      onClick={() => {
                        const randomSubmissionID = getRandomSubmission()
                        console.log("Will load random submission :", randomSubmissionID)
                        handleLoadSubmission(randomSubmissionID)
                      }}
                    >
                      Mark Random
                    </Button>

                    <Button
                      color="primary"
                      fullWidth
                      variant="text"
                      onClick={() => {
                        // setIsMarking(true)
                        handleLoadSubmission(selectedSubmission)

                      }}
                    >
                      Mark Selected
                    </Button>

                  </div>

                </CardActions>
              </Card>
            </Grid>


            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <form
                autoComplete="off"
                noValidate
              >
                <Card>
                  <CardHeader
                    // subheader="Please complete the following session"
                    title="Submission List"
                    action={<>


                    </>}

                  />
                  {/* <Divider /> */}

                  <CardContent >

                    <DataGrid
                      rows={submissions}
                      columns={paperColumns}
                      style={{ height: 400 }}
                      onCellClick={handleCellSelect}
                    />
                    <Typography>
                      {`Progress: ${progress}/${submissions.length}`}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    {/* 
                    <MDButton variant="gradient"
                      color="info"
                      onClick={handleProfileConfirmAlertOpen}
                      disabled={!profileConfirmEnabled}
                    >
                      Confirm
                    </MDButton> */}

                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>}

        {/* </Container> */}
      </Box>
      <Footer />


      <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleSuccessAlertClose}>
        <Alert onClose={handleSuccessAlertClose} severity="success" sx={{ width: '100%' }}>
          {successAlertMessage ? successAlertMessage : "Success"}
        </Alert>
      </Snackbar>

      <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleErrorAlertClose}>
        <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
          {errorAlertMessage ? errorAlertMessage : "Error Occuered"}
        </Alert>
      </Snackbar>

      {/* Pending Approval Dialog */}
      <Dialog
        open={!approved}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {waitingApproval ? "Awaiting Confirmation" : "Register"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            {waitingApproval ? `Waiting approval...\tID: ${uid}` : "Please enter your name"}
          </DialogContentText>
          {waitingApproval ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <CircularProgress />
          </div > : <>
            <TextField
              fullWidth
              // helperText="Please specify the first name"
              label="Display Name"
              name="displayName"
              placeholder='Tai Man'
              onChange={(event) => {
                const value = event.target.value
                console.log(value)
                setDisplayname(value)
              }}
              required
              value={displayName ? displayName : ""}
              // defaultValue={"First Name"}
              variant="outlined"
            // disabled={!profileConfirmEnabled}
            />


            <DialogActions>
              {/* <Button onClick={() => { }}>Go Back</Button> */}
              <Button onClick={() => {
                handleProfileInit()

              }} autoFocus>
                Confirm
              </Button>

            </DialogActions>
          </>}

        </DialogContent>


      </Dialog>

      {/* Settings Dialog */}

      <Dialog
        open={showSettingsDialog}
        onClose={handleCloseSettingsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Settings
        </DialogTitle>
        <DialogContent>

          <TextField label={"Default PDF page number"} value={defaultPDFPage} onChange={(event) => { setdefaultPDFPage(event.target.value) }} style={{ margin: spacing.list }} />


          <DialogActions>
            {/* <Button onClick={() => { }}>Go Back</Button> */}
            <Button onClick={() => {
              handleCloseSettingsDialog()

            }} autoFocus>
              Confirm
            </Button>

          </DialogActions>

        </DialogContent>


      </Dialog>


    </DashboardLayout >

  )
}